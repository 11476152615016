import React from "react";
import ReactDOM from "react-dom/client";
import reportWebVitals from "./reportWebVitals";
import { Provider } from "react-redux";
import { store } from "./redux/store";
import { BrowserRouter, Routes, Route } from "react-router-dom";
import NotFound from "./componants/main/not-found/NotFound";
//styles
import "../src/styles/general.scss";
import "react-responsive-carousel/lib/styles/carousel.min.css";
//components

import App from "./componants/main/Home/CompanyInformation";
import Gallery from "./componants/main/Galeries/Gallery";
import Services from "./componants/main/Services/Services";
import Contact from "./componants/main/Contact/Contact";
import Connection from "./componants/main/login/Connection";
import Dashboard from "./componants/main/dashboard/Dashboard";
import LegalNotice from "./componants/main/legal-notice/LegalNotice";
import Navbar from "./componants/header/Navbar.header";
import Footer from "./componants/footer/Footer.footer";
import Anchor from "./componants/tools/Anchor.tools";
import ForgottenPassword from "./componants/main/login/ForgottenPassword";

const root = ReactDOM.createRoot(document.getElementById("root"));
root.render(
  <BrowserRouter>
    <Provider store={store}>
      <Navbar />
      <Routes>
        <Route path="/" element={<App />} />
        <Route path="/realisations" element={<Gallery />} />
        <Route path="/services" element={<Services />} />
        <Route path="/contact" element={<Contact />} />
        <Route path="/mention-legales" element={<LegalNotice />} />
        <Route path="/restricted-area" element={<Connection />} />
        <Route path="/password-recovery" element={<ForgottenPassword/>}/>
        <Route path="/dashboard" element={<Dashboard />} />
        <Route path="*" element={<NotFound />} status={404} />
      </Routes>
      <Anchor/>
      <Footer />
    </Provider>
  </BrowserRouter>
);

reportWebVitals();
