import React from "react";

const InputAddress = ({ value, onChange }) => {
  return (
    <>
      <input
        type="text"
        name="adresse"
        id="adresse"
        title="Votre adresse"
        placeholder="Adresse postale"
        value={value}
        onChange={onChange}
        required
        autoComplete="address-level1"
      />
    </>
  );
};

export default InputAddress;
