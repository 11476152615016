import React, { useState, useRef } from "react";
import Button from "../../../tools/buttons/Button.tools";
import { postRequest } from "../../../../api/api";

const Form = () => {
  const content = "Envoyer";
  const [inputError, setInputError] = useState("");

  const [name, setName] = useState("");
  const [mobile, setMobile] = useState("");
  const [mail, setMail] = useState("");
  const [selectedValue, setSelectedValue] = useState("");
  const [message, setMessage] = useState("");
  const [statusMessage, setStatusMessage] = useState("");
  const [updateMessage, setUpdateMessage] = useState("");

  const [invalidValueEmail, setInvalidValueEmail] = useState("");
  const [invalidValueName, setInvalidValueName] = useState("");
  const [invalidValueMobile, setInvalidValueMobile] = useState("");
  const [invalidValueMessage, setInvalidValueMessage] = useState("");


  //use useRef() to change the color of the input border in the event of an error
  const inputEmailRef = useRef(null);
  const inputMessageRef = useRef(null);
  const inputNameRef = useRef(null);
  const inputMobileRef = useRef(null);



  const handleNameChange = (event) => {
    setName(event.target.value);
  };

  const handleMobileChange = (event) => {
    setMobile(event.target.value);
  };

  const handleMailChange = (event) => {
    setMail(event.target.value);
  };

  const handleSelectChange = (event) => {
    setSelectedValue(event.target.value);
  };

  const handleMessageChange = (event) => {
    setMessage(event.target.value);
  };

  const handleFormSubmit = async (event) => {
    event.preventDefault();

    const formData = {
      name,
      mobile,
      email: mail,
      reason: selectedValue,
      message,
    };

    const response = await postRequest("send-email", formData);
    try {
      if (response.status === 200) {
        //If there was an error when filling in the fields, we remove the red color defined at the time of the error.
        if (invalidValueEmail === "red") {
          setInvalidValueEmail(
            (inputEmailRef.current.style.borderColor = "unset")
          );
        }
        if (invalidValueName === "red") {
          setInvalidValueName(
            (inputNameRef.current.style.borderColor = "unset")
          );
        }
        if (invalidValueMobile === "red") {
          setInvalidValueMobile(
            (inputMobileRef.current.style.borderColor = "unset")
          );
        }
        if (invalidValueMessage === "red") {
          setInvalidValueMessage(
            (inputMessageRef.current.style.borderColor = "unset")
          );
        }

        setMail("");
        setMessage("");
        setMobile("");
        setName("");
        setSelectedValue("");
        setUpdateMessage(
          "L'email a été envoyé avec succès, vous serez recontacté dans les meilleurs délais ! Le mail est introuvable ? Pensez à regarder vos spams. "
        );
        setStatusMessage("success-message");
        setInputError("");
      } else if (response.status === 400) {
        if (!message || message.length < 10) {
          setInputError("Le message ne peut être vide et doit contenir au minimum 10 caractères ! ");
          setInvalidValueMessage(
            (inputMessageRef.current.style.borderColor = "red")
          );
          setUpdateMessage("Une erreur est survenue, votre e-mail n'a pas pu être envoyé. Vérifiez les champs obligatoires.");
          setStatusMessage("error-message");
          return;
        }
        if (
          !mail ||
          !mail.match(
            "^(?:[\\w\\-]+(?:\\.[\\w\\-]+)*)@((?:[\\w\\-]+\\.)*\\w[\\w\\-]{0,66})\\.([a-z]{2,6}(?:\\.[a-z]{2})?)$\n"
          )
        ) {
          setInputError("Merci de fournir un email valide !");
          setInvalidValueEmail(
            (inputEmailRef.current.style.borderColor = "red")
          );
        }
        if (
          !mobile ||
          mobile.length > 10 ||
          mobile.length < 10 ||
          !mobile.match("^0[1-9]([-.s]?[0-9]{2}){4}$")
        ) {
          setInvalidValueMobile(
            (inputMobileRef.current.style.borderColor = "red")
          );
          setInputError(
            "Merci de fournir un numéro de téléphone valide ! exemple : 0123456789"
          );
        }

        if (!name) {
          setInvalidValueName((inputNameRef.current.style.borderColor = "red"));
          setInputError(
            "Merci de fournir votre nom afin de faciliter la prise de contact !"
          );
        }

        if (!selectedValue) {
          setInputError("Merci de spécifier la raison de votre contact !");
        }

        setUpdateMessage(
          "Une erreur est survenue, votre e-mail n'a pas pu être envoyé. Vérifiez les champs obligatoires."
        );
        setStatusMessage("error-message");
      } else {
        console.log("Erreur lors de l'envoie");
        setStatusMessage("error-message");
        setUpdateMessage(
          "Une erreur est survenue lors de l'envoie de votre message, veuillez réessayer ultérieurement ou prendre contact par téléphone"
        );
      }
    } catch (error) {
      console.error(error);
    }
  };
  return (
    <>
      <p className={`message ${statusMessage}`}>{updateMessage}</p>
      <p className={`message ${statusMessage}`}>{inputError}</p>
      <form
        role="contact"
        onSubmit={handleFormSubmit}
        aria-label="Envoyer un email à TJ Peinture pour prendre contact"
      >
        <h4>Besoin d'un devis ou de renseignements ?</h4>
        <legend>
          <span>*</span> Champs obligatoires
        </legend>
        <div>
          <label htmlFor="name">
            Nom <span>*</span>
          </label>
          <input
            type="text"
            name="name"
            value={name}
            onChange={handleNameChange}
            placeholder="Votre nom"
            required
            title="Indiquez votre nom pour faciliter la prise de contact"
            ref={inputNameRef}
          />
        </div>
        <div>
          <label htmlFor="tel">
            N° de téléphone <span>*</span>
          </label>
          <input
            type="tel"
            value={mobile}
            onChange={handleMobileChange}
            placeholder="Votre téléphone"
            name="tel"
            title="Exemple : 01 02 03 04 05"
            required
            pattern="^0[1-9]([\-.\s]?[0-9]{2}){4}$"
            ref={inputMobileRef}
          />
        </div>
        <div>
          <label htmlFor="email">
            Email <span>*</span>
          </label>
          <input
            type="email"
            value={mail}
            onChange={handleMailChange}
            placeholder="Votre email"
            name="email"
            title="Exemple de format : votre.email@gmail.fr"
            alt="Exemple de format : votre.email@gmail.fr"
            pattern="^(?:[\w\-]+(?:\.[\w\-]+)*)@((?:[\w\-]+\.)*\w[\w\-]{0,66})\.([a-z]{2,6}(?:\.[a-z]{2})?)$"
            ref={inputEmailRef}
            required
          />
        </div>
        <div>
          <label>
            Vous me contactez pour... <span>*</span>
          </label>
          <select
            placeholder="services, devis..."
            value={selectedValue}
            onChange={handleSelectChange}
            name="select"
            required
            title="Sélectionner la raison de votre prise de contact"
          >
            <option value={""}>Choisir une option</option>
            <option value="Rendez-vous">Rendez-vous</option>
            <option value="Devis">Devis</option>
            <option value="Renseignements">Renseignements</option>
            <option value="Autre raison">Autre raison</option>
          </select>
        </div>
        <div>
          <label htmlFor="message">
            Votre message <span>*</span>
          </label>
          <textarea
            placeholder="Votre message (10 caractères minimum)"
            value={message}
            onChange={handleMessageChange}
            name="message"
            title="Écrivez votre message, il sera envoyé à TJ Peinture"
            cols="30"
            rows="10"
            required
            minLength={10}
            ref={inputMessageRef}
          ></textarea>
        </div>
        <Button content={content} />
      </form>
    </>
  );
};

export default Form;
