import React, { useEffect, useState } from "react";
import mc from "./navbar.module.scss";
import Waveh from "./Vector-header.svg";
import Logo from "./logo192.png";
import { RxHamburgerMenu } from "react-icons/rx";
import { AiOutlineLogout } from "react-icons/ai";
import Button from "../tools/buttons/Button.tools";
import { NavLink } from "react-router-dom";
import { MdManageAccounts } from "react-icons/md";
import { checkLoggedIn, logOut } from "../../utils/storage.utils";
import { useDispatch, useSelector } from "react-redux";
import { setLoggedIn, loggedInChecked } from "../../redux/reducers/user.slice";

const Navbar = () => {
  const [showMenu, setShowMenu] = useState(false);
  const content = <a href="tel:0677650306">Appelez-moi</a>;

  const dispatch = useDispatch();
  //Extracts the "isLoggedIn" and "isLoggedInChecked" properties from the Redux store
  const { isLoggedIn, isLoggedInChecked } = useSelector((store) => store.user);

  const handleLogout = async () => {
    //Calls the logOut function to disconnect the user and stores the result in a "logOutSuccessful" constant
    const logOutSuccessful = await logOut();
    dispatch(setLoggedIn(!logOutSuccessful));
  };
  // When clicking on the element, displays or not the menu
  const handleMenuClick = () => {
    setShowMenu(!showMenu);
  };
  
  useEffect(() => {
    // Defines a fetchLoggedIn function that calls the checkLoggedIn function to check if the user is logged in and updates the Redux store status
    const fetchLoggedIn = async () => {
      const loggedInSuccessful = await checkLoggedIn();
      dispatch(setLoggedIn(loggedInSuccessful));
      //Verify if the request has been sent
      dispatch(loggedInChecked());
    };
    // Checks if the isLoggedInChecked state is false to call the fetchLoggedIn function and check if the user is connected
    if (!isLoggedInChecked) {
      fetchLoggedIn();
    }
  }, []);
  return (
    <header role="banner">
      {/* Start nav-bar */}
      <nav
        className={mc["nav-header"]}
        role="navigation"
        aria-label="Menu principal"
      >
        <div className={mc["content-nav"]}>
          <a href="/">
            <img
              src={Logo}
              alt="Logo de la sociéte TJ Peinture, représente son propriétaire"
              loading="lazy"
            />
          </a>
          {/* Menu Burger */}
          <RxHamburgerMenu className={mc.menu} onClick={handleMenuClick} />

          <ul
            className={`${mc["nav-list"]} ${
              showMenu ? mc["nav-list-show"] : ""
            }`}
          >
            <li>
              <NavLink
                to="/"
                aria-label="Se rendre sur la page d'accueil"
                onClick={() => setShowMenu(false)}
              >
                ACCUEIL
              </NavLink>
            </li>
            <li>
              <NavLink
                to="/realisations"
                aria-label="Se rendre sur la page : Galeries "
                onClick={() => setShowMenu(false)}
              >
                GALERIES
              </NavLink>
            </li>
            <li>
              <NavLink
                to="/services"
                aria-label="Se rendre sur la page : Services proposés"
                onClick={() => setShowMenu(false)}
              >
                SERVICES PROPOSÉS
              </NavLink>
            </li>
            <li>
              <NavLink
                to="/contact"
                aria-label="Se rendre sur la page : Contact"
                onClick={() => setShowMenu(false)}
              >
                CONTACT
              </NavLink>
            </li>
            {/* If connected, displays the link to access the site settings  */}
            {isLoggedIn && (
              <>
                <li>
                  <NavLink
                    to="/dashboard"
                    aria-label="Se rendre sur la page du tableau de bord administrateur"
                    onClick={() => setShowMenu(false)}
                  >
                    <MdManageAccounts className="icons-btn" />
                    Mon tableau de bord
                  </NavLink>
                </li>
                <li>
                  <AiOutlineLogout
                    onClick={handleLogout}
                    className="icons-btn logout"
                    aria-label="Se déconnecter du site"
                  />
                </li>
              </>
            )}
            {/* If not connected, remove the button link  */}
            {!isLoggedIn && (
              <Button
                className={mc.btn}
                content={content}
                aria-label="Appeler TJ Peinture"
              />
            )}
          </ul>
        </div>
        {/* Wave */}
        <img className={mc.wave} src={Waveh} alt="SVG wave header" />
      </nav>
      {/* End nav-bar */}
    </header>
  );
};

export default Navbar;
