import { createSlice } from "@reduxjs/toolkit";
import { getRequest } from "../../api/api";

// Action Creator which allows you to retrieve the company's information
export const fetchCompanyInformations = () => async (dispatch, getState) => {
    try {
        const loading = getState().company.loading;
        if (loading) {
            return;
        }
        dispatch(startLoading())
        const {status, result} = await  getRequest('company/', true)
        if (status === 200) {
            // Storage of information retrieved in the Redux store thanks to actions
            const address = result.data.address
            const mobile = result.data.mobile
            const siret = result.data.siret       
            dispatch(setAddress(address))
            dispatch(setMobile(mobile))
            dispatch(setSiret(siret))
        }

    } catch (error) {
        console.error({message : "An error occured", error})
    } finally {
        dispatch(stopLoading())
    }
}
export const companySlice = createSlice({
    name : "company",
    initialState : {
        loading : false, 
        mobile : "",
        address : "",
        siret : ""
    },
    reducers : {
        startLoading : (state) => {
            return { ...state, loading : true}
        },
        stopLoading : (state) => {
            return { ...state, loading : false}
        },
        //Company information
        //--get
        setMobile : (state, action) => {
            return { ...state, mobile : action.payload}
        }, 
        setAddress : (state, action) => {
            return {...state, address : action.payload}
        },
        setSiret : (state, action) => {
            return { ...state, siret : action.payload}
        },
        //--update
        updateMobile : (state, action) => {
            return { ...state, mobile : action.payload}
        },
        updateAddress : (state, action) => {
            return { ...state, address : action.payload}
        },
        updateSiret : (state, action) => {
            return { ...state, siret : action.payload}
        }
        
    }
})

export const {startLoading, stopLoading, setMobile, setAddress, setSiret} = companySlice.actions;
export default companySlice.reducer;