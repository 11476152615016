import { configureStore } from "@reduxjs/toolkit";
import userReducer from "./reducers/user.slice"
import sectionReducer from "./reducers/sections.slice";
import projectReducer from "./reducers/projects.slice";
import modalsReducer from "./reducers/modals.slice";
import companyReducer from "./reducers/company.slice";
import pictureReducer from "./reducers/pictures.slice"

export const store = configureStore({
  //Contains the various reducers used
  reducer: {
    user : userReducer,
    section : sectionReducer,
    project : projectReducer,
    modals: modalsReducer,
    company : companyReducer,
    picture : pictureReducer, 
  },
});
