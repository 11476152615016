import { createSlice } from "@reduxjs/toolkit";

const modalsSlice = createSlice({
    name : "modals",
    initialState : {
       editPassword : false,
       editEmail : false,
       editAddress : false,
       editMobile : false, 
       editSiret : false,
       editSection : false,
       addProject : false,
       editProject : false,
       addPicture : false,
       deleteModalProject : false,
       deleteModalPicture : false,
       imagePreviewModal : false,
       pictureCarousel : false,
       deletePictureCarousel : false

        
    },
    reducers : {
        openModal : (state, action) => {            
            const modalActive = action.payload;
            state[modalActive] = true;
        },
        closeModal : (state, action) => {
            const modalActive = action.payload;
            state[modalActive] = false;
        }
    }
})

export const {openModal, closeModal} = modalsSlice.actions;
export default modalsSlice.reducer;