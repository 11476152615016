import React, { useEffect } from "react";
import mccontact from "./contact.module.scss";
import Form from "./items/Form.contact";
import { useDispatch } from "react-redux";
import { fetchUserInformations } from "../../../redux/reducers/user.slice";
import { fetchCompanyInformations } from "../../../redux/reducers/company.slice";
import { Helmet } from "react-helmet";
import { AnimatePresence, motion } from "framer-motion";
import Logo from "../../../img/logo192.png";


const Contact = () => {
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(fetchUserInformations());
    dispatch(fetchCompanyInformations());
  }, []);
  return (
    <>
      <Helmet>
        <title>
          Contactez TJ Peinture - Demande de devis personnalisés | TJ Peinture
        </title>
        <meta
          name="description"
          content="Contactez TJ Peinture pour des devis de peinture gratuits et personnalisés."
        />
        <meta
          name="keywords"
          content="TJ Peinture, peinture, contact, devis gratuits, rénovation, décoration intérieure, décoration extérieure"
        />
         {/* Meta robots */}
         <meta name="robots" content="index, unfollow" />
        {/* Open Graph balises for social networks */}

        <meta property="og:image" content={Logo} />
        <meta
          property="og:description"
          content="Contact | Peinture Intérieure - Peinture extérieure - Peinture Bâtiment - Rénovation , ..."
        />
        <meta property="og:locale" content="fr_FR" />
        <meta property="og:title" content="TJ Peinture - Contact" />
      </Helmet>
      <AnimatePresence>
        <motion.main
          initial={{ opacity: 0 }}
          animate={{ opacity: 1 }}
          exit={{ opacity: 0 }}
          key="contact"
          className={` ${mccontact.contact} container`}
          id="top"
          role="main"
        >
          <div className={mccontact["title"]}>
            <h2>Contact</h2>
            <h3>Devis gratuits et personnalisés ! </h3>
          </div>
          <Form />
        </motion.main>
      </AnimatePresence>
    </>
  );
};

export default Contact;
