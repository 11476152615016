import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import InputPasswordUpdate from "../items/user-company/InputPasswordUpdate";
import InputEmail from "../items/user-company/InputEmail";
import Modal from "../../../tools/Modal.tools";
import { putRequest } from "../../../../api/api";
import { openModal, closeModal } from "../../../../redux/reducers/modals.slice";
import InputAddress from "../items/user-company/InputAddress";
import InputMobile from "../items/user-company/InputMobile";
import InputSiret from "../items/user-company/InputSiret";
import {
  fetchUserInformations,
  setEmail,
} from "../../../../redux/reducers/user.slice";
import {
  fetchCompanyInformations,
  setAddress,
  setMobile,
  setSiret,
} from "../../../../redux/reducers/company.slice";
import SendBtn from "../../../tools/buttons/SendBtn";
import { handleUpdate } from "../../../../utils/handle-change";
import mcdashboard from "../dashboard.module.scss";
import EditBtn from "../../../tools/buttons/EditBtn.tools";
import { AnimatePresence, motion } from "framer-motion";

const MyInformations = () => {
  const dispatch = useDispatch();
  const isPasswordModalOpen = useSelector((state) => state.modals.editPassword);
  const isEmailModalOpen = useSelector((state) => state.modals.editEmail);
  const isAddressModalOpen = useSelector((state) => state.modals.editAddress);
  const isMobileModalOpen = useSelector((state) => state.modals.editMobile);
  const isSiretModalOpen = useSelector((state) => state.modals.editSiret);
  const email = useSelector((state) => state.user.email);
  const { address, mobile, siret } = useSelector((state) => state.company);
  const [newEmail, setNewEmail] = useState("");
  const [newPassword, setNewPassword] = useState("");
  const [newAddress, setNewAddress] = useState("");
  const [newSiret, setNewSiret] = useState("");
  const [newMobile, setNewMobile] = useState("");
  const userId = useSelector((state) => state.user.id);
  const [updateMessage, setUpdateMessage] = useState(null);
  const [statusMessage, setStatusMessage] = useState("");

  const updateUser = async (updateField) => {
    try {
      const response = await putRequest(`user/${userId}`, updateField, true);
      if (response.status === 200) {
        setUpdateMessage("Les informations ont été mises à jour avec succès");
        setStatusMessage("success-message");
        dispatch(setEmail(newEmail));
        handleCloseModal();
      } else {
        setUpdateMessage("La mise à jour des informations n'a pas aboutis");
        setStatusMessage("error-message");
      }
    } catch (error) {
      console.error(error);
    }
  };
  const updateCompany = async (updateField) => {
    try {
      const response = await putRequest(`company/`, updateField, true);
      if (response.status === 200) {
        setUpdateMessage("Les informations ont été mises à jour avec succès");
        dispatch(setAddress(newAddress || address));
        dispatch(setMobile(newMobile || mobile));
        dispatch(setSiret(newSiret || siret));
      } else {
        setUpdateMessage("La mise à jour des informations n'a pas aboutis");
      }
    } catch (error) {
      console.error(error);
    }
  };
  const modalsTitles = {
    editPassword: "Modifier le mot de passe",
    editEmail: "Modifier l'adresse e-mail",
    editAddress: "Modifier l'adresse",
    editMobile: "Modifier le numéro de téléphone",
    editSiret: "Modifier le numéro SIRET",
  };

  //Check the value change
  const handleOpenModal = (modalToOpen) => {
    dispatch(openModal(modalToOpen));
  };

  const handleCloseModal = (modalToClose) => {
    dispatch(closeModal(modalToClose));
  };

  //Forms submit

  const handlePasswordSubmit = (e) => {
    e.preventDefault();
    handleUpdate("password", newPassword, updateUser);
    handleCloseModal("editPassword");
    setNewPassword("");
  };
  const handleEmailSubmit = (e) => {
    e.preventDefault();
    handleUpdate("email", newEmail, updateUser);
    handleCloseModal("editEmail");
    setNewEmail("");
  };
  const handleAddressSubmit = (e) => {
    e.preventDefault();
    handleUpdate("address", newAddress, updateCompany);
    handleCloseModal("editAddress");
    setNewAddress("");
  };
  const handleMobileSubmit = (e) => {
    e.preventDefault();
    handleUpdate("mobile", newMobile, updateCompany);
    handleCloseModal("editMobile");
    setNewMobile("");
  };
  const handleSiretSubmit = (e) => {
    e.preventDefault();
    handleUpdate("siret", newSiret, updateCompany);
    handleCloseModal("editSiret");
    setNewSiret("");
  };
  const modalsContents = {
    editPassword: (
      <form onSubmit={handlePasswordSubmit}>
        <InputPasswordUpdate
          value={newPassword}
          onChange={(e) => setNewPassword(e.target.value)}
        />
        <SendBtn />
      </form>
    ),
    editEmail: (
      <form onSubmit={handleEmailSubmit}>
        <InputEmail
          value={newEmail}
          onChange={(e) => setNewEmail(e.target.value)}
        />
        <SendBtn />
      </form>
    ),
    editAddress: (
      <form onSubmit={handleAddressSubmit}>
        <InputAddress
          value={newAddress}
          onChange={(e) => setNewAddress(e.target.value)}
        />
        <SendBtn />
      </form>
    ),
    editMobile: (
      <form onSubmit={handleMobileSubmit}>
        <InputMobile
          value={newMobile}
          onChange={(e) => setNewMobile(e.target.value)}
        />
        <SendBtn />
      </form>
    ),
    editSiret: (
      <form onSubmit={handleSiretSubmit}>
        <InputSiret
          value={newSiret}
          onChange={(e) => setNewSiret(e.target.value)}
        />
        <SendBtn />
      </form>
    ),
  };
  useEffect(() => {
    dispatch(fetchUserInformations());
    dispatch(fetchCompanyInformations());
  }, []);
  return (
    <AnimatePresence>
      <motion.section
        initial={{ opacity: 0 }}
        animate={{ opacity: 1 }}
        exit={{ opacity: 0 }}
        key="informations-dashboard"
        className={mcdashboard["section-content"]}
      >
        <p className={` message ${statusMessage}`}>{updateMessage}</p>
        <table className={mcdashboard["table-informations"]}>
          <caption>Mes informations personnelles</caption>
          <tbody>
            <tr>
              <th>Mon adresse e-mail :</th>
              <td>
                <p>{email ? email : "teddy.jeantet26@gmail.com"}</p>
              </td>

              <td data-label="Actions">
                <EditBtn onClick={() => handleOpenModal("editEmail")} />
              </td>
            </tr>
            <tr>
              <th>Mon mot de passe :</th>
              <td>
                <p>********</p>
              </td>
              <td data-label="Actions">
                <EditBtn onClick={() => handleOpenModal("editPassword")} />
              </td>
            </tr>
          </tbody>
        </table>

        <table className={mcdashboard["table-informations"]}>
          <caption>Les informations de ma société</caption>
          <tbody>
            <tr>
              <th>Nom :</th>
              <td>
                <p>TjPeinture</p>
              </td>
            </tr>
            <tr>
              <th>Adresse :</th>
              <td>
                <p>
                  {address ? address : "372 Rue Léon Bourgeois 34500 Béziers"}
                </p>
              </td>
              <td data-label="Actions">
                <EditBtn onClick={() => handleOpenModal("editAddress")} />
              </td>
            </tr>
            <tr>
              <th>Numéro de téléphone :</th>
              <td>
                <p>{mobile ? mobile : "0677650306"}</p>
              </td>
              <td data-label="Actions">
                <EditBtn onClick={() => handleOpenModal("editMobile")} />
              </td>
            </tr>
            <tr>
              <th>Siret :</th>
              <td>
                <p>{siret ? siret : "90250512200017"}</p>
              </td>
              <td data-label="Actions">
                <EditBtn onClick={() => handleOpenModal("editSiret")} />
              </td>
            </tr>
          </tbody>
        </table>

        {isPasswordModalOpen && (
          <section className="modal-container">
            <Modal
              title={modalsTitles.editPassword}
              content={modalsContents.editPassword}
              onClose={() => handleCloseModal("editPassword")}
            />
          </section>
        )}
        {isSiretModalOpen && (
          <section className="modal-container">
            <Modal
              title={modalsTitles.editSiret}
              content={modalsContents.editSiret}
              onClose={() => handleCloseModal("editSiret")}
            />
          </section>
        )}
        {isEmailModalOpen && (
          <section className="modal-container">
            <Modal
              title={modalsTitles.editEmail}
              content={modalsContents.editEmail}
              onClose={() => handleCloseModal("editEmail")}
            />
          </section>
        )}
        {isMobileModalOpen && (
          <section className="modal-container">
            <Modal
              title={modalsTitles.editMobile}
              content={modalsContents.editMobile}
              onClose={() => handleCloseModal("editMobile")}
            />
          </section>
        )}
        {isAddressModalOpen && (
          <section className="modal-container">
            <Modal
              title={modalsTitles.editAddress}
              content={modalsContents.editAddress}
              onClose={() => handleCloseModal("editAddress")}
            />
          </section>
        )}
      </motion.section>
    </AnimatePresence>
  );
};

export default MyInformations;
