import React from "react";
import mc from "../home.module.scss";

const Maps = ({address}) => {
  return (
    <section className={`${mc.presentation} ${mc.map} container `}>
      <iframe
        title="Map situant l'entreprise Tj Peinture"
        width="100%"
        height="350"
        frameBorder="0"
        scrolling="no"
        marginHeight="0"
        marginWidth="0"
        src="https://www.openstreetmap.org/export/embed.html?bbox=3.2343256473541264%2C43.361215960043%2C3.2405215501785283%2C43.36362230137857&amp;layer=mapnik&amp;marker=43.362419142641386%2C3.237423598766327"
        style={{ border: "1px solid black" }}
      ></iframe>

      <small>
        <a href="https://www.openstreetmap.org/?mlat=43.36242&amp;mlon=3.23742#map=19/43.36242/3.23742">
          Afficher une carte plus grande
        </a>
        <article>
          <p>{address}</p>
        </article>
      </small>
    </section>
  );
};

export default Maps;
